<template>
    <div class="content">
        <ul>
            <li v-for="(item, index) in data.items" :key="index">
                <div class="icon"><img :src="item.icon" /></div>
                <div class="title">{{ item.title }}</div>
            </li>
            <div class="clear"></div>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
        console.log(this.data);
    },
    data() {
        return {};
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.clear {
    clear: both;
}

.content {
    width: 100%;
    max-width: 1200px;
    margin: 60px auto;
    display: block;
}

.content li {
    width: 23%;
    float: left;
    margin: 10px 1%;
    text-align: center;
}

.icon {
    height: 100px;
    width: 40%;
    margin: 0 auto;
}

.icon img {
    height: 100%;
    max-width: 100%;
}

.title {
    height: 30px;
    margin: 2vw auto;
    font-size: 20px;
    color: #467ed1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

@media screen and (max-width: 700px) {
    .content li {
        width: 46%;
    }

    .icon {
        height: 18vw;
        width: 50%;
    }

    .title {
        height: 40px;
        margin: 4vw auto;
        font-size: 28px;
    }
}
</style>
